import { ValuesConfiguration } from '../types/valuesConfiguration';
import axios from './axios';

const getValuesConfigurations = async (): Promise<ValuesConfiguration[]> => {
  const response = await axios.get('/values-configuration');

  return response.data.data;
};

export const valuesConfigurationService = { getValuesConfigurations };
